var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import { MENSAGENS } from '../utils';
import { getTokenFromCookie } from '../services/mt_login';
export var URL_API = (_a = process.env.URL_API) !== null && _a !== void 0 ? _a : '';
export var DEV = process.env.AMBIENTE == 'DEV';
var REST_METHODS = {
    GET: 'GET',
    POST: 'POST',
    PATCH: 'PATCH',
    DELETE: 'DELETE',
};
export var STATUS_API = {
    OK: 'OK',
    ERRO: 'ERRO',
};
export var fetchCadastrar = function (URL, Dados, TOKEN_PARM) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                DEV && console.log('fetchCadastrar : ', URL);
                return [4 /*yield*/, fetchAPI("".concat(URL_API, "/").concat(URL), REST_METHODS.POST, Dados, TOKEN_PARM)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var fetchAlterar = function (URL, Dados, TOKEN_PARM) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                DEV && console.log('fetchAlterar : ', URL);
                return [4 /*yield*/, fetchAPI("".concat(URL_API, "/").concat(URL), REST_METHODS.PATCH, Dados, TOKEN_PARM)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var fetchDeletar = function (URL, TOKEN_PARM) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                DEV && console.log('fetchDeletar : ', URL);
                return [4 /*yield*/, fetchAPI("".concat(URL_API, "/").concat(URL), REST_METHODS.DELETE, null, TOKEN_PARM)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
var fetchAPI = function (URL, Metodo, Dados, TOKEN_PARM) { return __awaiter(void 0, void 0, void 0, function () {
    var TOKEN, Params, Response, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                TOKEN = TOKEN_PARM !== null && TOKEN_PARM !== void 0 ? TOKEN_PARM : getTokenFromCookie();
                Params = {
                    method: Metodo,
                    headers: {
                        Authorization: "Bearer ".concat(TOKEN),
                    },
                };
                if (Dados) {
                    Params.headers['Content-Type'] = 'application/json';
                    Params.body = JSON.stringify(Dados);
                }
                return [4 /*yield*/, fetch("".concat(URL), Params)];
            case 1:
                Response = _a.sent();
                return [2 /*return*/, getRespostasPadraoAPI(Response)];
            case 2:
                error_1 = _a.sent();
                return [2 /*return*/, getErrorTratado(error_1)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var fetchConsultar = function (URL, TOKEN_PARM) { return __awaiter(void 0, void 0, void 0, function () {
    var TOKEN, Response, error_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                DEV && console.log('fetchConsultar : ', URL);
                _a.label = 1;
            case 1:
                _a.trys.push([1, 6, , 7]);
                TOKEN = TOKEN_PARM !== null && TOKEN_PARM !== void 0 ? TOKEN_PARM : getTokenFromCookie();
                return [4 /*yield*/, fetch("".concat(URL_API, "/").concat(URL), {
                        method: REST_METHODS.GET,
                        headers: {
                            Authorization: "Bearer ".concat(TOKEN),
                        },
                    })];
            case 2:
                Response = _a.sent();
                if (!(Response && Response.status >= 200 && Response.status < 300)) return [3 /*break*/, 4];
                return [4 /*yield*/, Response.json()];
            case 3: return [2 /*return*/, _a.sent()];
            case 4: return [2 /*return*/, getRespostasPadraoAPI(Response)];
            case 5: return [3 /*break*/, 7];
            case 6:
                error_2 = _a.sent();
                return [2 /*return*/, getErrorTratado(error_2)];
            case 7: return [2 /*return*/];
        }
    });
}); };
export var fetchConsultarPaginada = function (URL, PaginationModel, TOKEN_PARM) { return __awaiter(void 0, void 0, Promise, function () {
    var TOKEN, Response, Headers, error_3;
    var _a;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                // OBS
                // A grid trabalha com o valor da página iniciando em 0, porem melhor trablhar com 1, então sempre que for passar o valor da página para a API, adicionar +1
                DEV && console.log('fetchConsultarPaginada : ', URL);
                _c.label = 1;
            case 1:
                _c.trys.push([1, 6, , 7]);
                TOKEN = TOKEN_PARM !== null && TOKEN_PARM !== void 0 ? TOKEN_PARM : getTokenFromCookie();
                return [4 /*yield*/, fetch("".concat(URL_API, "/").concat(URL), {
                        headers: {
                            Authorization: "Bearer ".concat(TOKEN),
                            'x-pagina': (PaginationModel.page + 1).toString(),
                            'x-qtd-por-pagina': PaginationModel.pageSize.toString(),
                        },
                    })];
            case 2:
                Response = _c.sent();
                if (!(Response && Response.status >= 200 && Response.status < 300)) return [3 /*break*/, 4];
                Headers = Response.headers;
                _a = { Status: 'OK' };
                return [4 /*yield*/, Response.json()];
            case 3: return [2 /*return*/, (_a.dados = _c.sent(), _a.total_registros = parseInt((_b = Headers.get('X-Total-Registros')) !== null && _b !== void 0 ? _b : '0'), _a)];
            case 4: return [2 /*return*/, getRespostasErroPadraoAPI(Response)];
            case 5: return [3 /*break*/, 7];
            case 6:
                error_3 = _c.sent();
                return [2 /*return*/, getErrorTratado(error_3)];
            case 7: return [2 /*return*/];
        }
    });
}); };
export var fetchArquivos = function (URL, Dados, TOKEN_PARM) { return __awaiter(void 0, void 0, void 0, function () {
    var TOKEN, Response, error_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                DEV && console.log('fetchArquivos : ', URL);
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                TOKEN = TOKEN_PARM !== null && TOKEN_PARM !== void 0 ? TOKEN_PARM : getTokenFromCookie();
                return [4 /*yield*/, fetch("".concat(URL_API, "/").concat(URL), {
                        method: REST_METHODS.POST,
                        headers: {
                            Authorization: "Bearer ".concat(TOKEN),
                        },
                        body: Dados,
                    })];
            case 2:
                Response = _a.sent();
                return [2 /*return*/, getRespostasPadraoAPI(Response)];
            case 3:
                error_4 = _a.sent();
                return [2 /*return*/, getErrorTratado(error_4)];
            case 4: return [2 /*return*/];
        }
    });
}); };
var getErrorTratado = function (error) {
    function isError(error) {
        return error instanceof Error;
    }
    DEV && console.error('Erro ao executar a solicitação para a API: ', isError(error) ? error.message : error);
    return { Status: 'ERRO', Mensagem: error && DEV ? (isError(error) ? error.message : error) : 'Erro ao conectar-se à API de comunicação' };
};
export var getRespostasPadraoAPI = function (Response) { return __awaiter(void 0, void 0, Promise, function () {
    var RespJson, error_5;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!(Response && Response.status >= 200 && Response.status < 300)) return [3 /*break*/, 5];
                RespJson = null;
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, Response.json()];
            case 2:
                RespJson = _a.sent();
                return [3 /*break*/, 4];
            case 3:
                error_5 = _a.sent();
                DEV && console.error('getRespostasPadraoAPI: ', error_5);
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/, { Status: 'OK', Mensagem: 'Operação realizada com sucesso!', dados: RespJson }];
            case 5: return [2 /*return*/, getRespostasErroPadraoAPI(Response)];
        }
    });
}); };
export var getRespostasErroPadraoAPI = function (Response) { return __awaiter(void 0, void 0, Promise, function () {
    var RespJson, MensagemErro, TipoErro, MensagemErroLower;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!Response) {
                    return [2 /*return*/, { Status: 'ERRO', Mensagem: 'Erro ao realizar a operação!' }];
                }
                return [4 /*yield*/, (Response === null || Response === void 0 ? void 0 : Response.json())];
            case 1:
                RespJson = _a.sent();
                MensagemErro = (RespJson === null || RespJson === void 0 ? void 0 : RespJson.message) ? RespJson.message : '';
                TipoErro = (RespJson === null || RespJson === void 0 ? void 0 : RespJson.error) ? RespJson.error : '';
                MensagemErroLower = MensagemErro.toLowerCase();
                if (TipoErro == 'Prisma Know Error') {
                    if (MensagemErroLower.indexOf('unique') >= 0) {
                        switch (true) {
                            case MensagemErroLower.indexOf('nome') >= 0:
                                return [2 /*return*/, { Status: 'ERRO', Mensagem: "O nome ".concat(MENSAGENS.REGISTRO_JA_CADASTRADO) }];
                            case MensagemErroLower.indexOf('cpf') >= 0:
                                return [2 /*return*/, { Status: 'ERRO', Mensagem: "O cpf ".concat(MENSAGENS.REGISTRO_JA_CADASTRADO) }];
                            case MensagemErroLower.indexOf('email') >= 0:
                                return [2 /*return*/, { Status: 'ERRO', Mensagem: "O e-mail ".concat(MENSAGENS.REGISTRO_JA_CADASTRADO) }];
                            case MensagemErroLower.indexOf('cnpj') >= 0:
                                return [2 /*return*/, { Status: 'ERRO', Mensagem: "O cnpj ".concat(MENSAGENS.REGISTRO_JA_CADASTRADO) }];
                            case MensagemErroLower.indexOf('codigo_ibge') >= 0:
                                return [2 /*return*/, { Status: 'ERRO', Mensagem: "O c\u00F3digo ibge ".concat(MENSAGENS.REGISTRO_JA_CADASTRADO) }];
                            default:
                                return [2 /*return*/, { Status: 'ERRO', Mensagem: MensagemErro }];
                        }
                    }
                    else {
                        if (MensagemErroLower.indexOf('column is too long') >= 0) {
                            return [2 /*return*/, { Status: 'ERRO', Mensagem: 'Quantidade de caracteres utilizado é maior do que a permitida' }];
                        }
                    }
                    return [2 /*return*/, { Status: 'ERRO', Mensagem: MensagemErro }];
                }
                else {
                    return [2 /*return*/, { Status: 'ERRO', Mensagem: 'Erro ao realizar a operação!' }];
                }
                return [2 /*return*/];
        }
    });
}); };
//DEPARTAMENTOS
var ROTA_DEPARTAMENTOS = 'departamentos';
export var getDepartamentos = function (TextoBusca, PaginationModel) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_DEPARTAMENTOS).concat(TextoBusca ? '/search/' + TextoBusca : '');
                return [4 /*yield*/, fetchConsultarPaginada(URL, PaginationModel)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var getDepartamento = function (ID) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchConsultar("".concat(ROTA_DEPARTAMENTOS, "/").concat(ID))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var cadastrarDepartamento = function (Dados) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_DEPARTAMENTOS);
                return [4 /*yield*/, fetchCadastrar(URL, Dados)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var alterarDepartamento = function (Dados, ID) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_DEPARTAMENTOS, "/").concat(ID);
                return [4 /*yield*/, fetchAlterar(URL, Dados)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var excluirDepartamento = function (ID) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_DEPARTAMENTOS, "/").concat(ID);
                return [4 /*yield*/, fetchDeletar(URL)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
//PERFIS
var ROTA_PERFIS = 'perfis';
export var getPerfis = function (TextoBusca, Status, PaginationModel) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_PERFIS);
                if (TextoBusca && Status != '-1') {
                    URL = "".concat(URL, "/search/").concat(TextoBusca, "/").concat(Status);
                }
                else if (TextoBusca) {
                    URL = "".concat(URL, "/search/").concat(TextoBusca);
                }
                else if (Status && Status != '-1') {
                    URL = "".concat(URL, "/search/null/").concat(Status);
                }
                return [4 /*yield*/, fetchConsultarPaginada(URL, PaginationModel)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var getPerfisAtivos = function () { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchConsultar("".concat(ROTA_PERFIS, "/ativos"))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var getPerfisFuncionalidadesAtivos = function () { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchConsultar("".concat(ROTA_PERFIS, "/funcionalidades"))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var getPerfil = function (ID) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchConsultar("".concat(ROTA_PERFIS, "/").concat(ID))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var cadastrarPerfil = function (Dados) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_PERFIS);
                return [4 /*yield*/, fetchCadastrar(URL, Dados)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var alterarPerfil = function (Dados, ID) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_PERFIS, "/").concat(ID);
                return [4 /*yield*/, fetchAlterar(URL, Dados)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var excluirPerfil = function (ID) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_PERFIS, "/").concat(ID);
                return [4 /*yield*/, fetchDeletar(URL)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
//ORGÃOS EMPRESAS
var ROTA_ORGAOS_EMPRESAS = 'orgaos';
export var getOrgaosEmpresas = function (TextoBusca, PaginationModel) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_ORGAOS_EMPRESAS).concat(TextoBusca ? '/search/' + TextoBusca : '');
                return [4 /*yield*/, fetchConsultarPaginada(URL, PaginationModel)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var getOrgaoEmpresa = function (ID) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchConsultar("".concat(ROTA_ORGAOS_EMPRESAS, "/").concat(ID))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var cadastrarOrgaoEmpresa = function (Dados) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_ORGAOS_EMPRESAS);
                return [4 /*yield*/, fetchCadastrar(URL, Dados)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var alterarOrgaoEmpresa = function (Dados, ID) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_ORGAOS_EMPRESAS, "/").concat(ID);
                return [4 /*yield*/, fetchAlterar(URL, Dados)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var excluirOrgaoEmpresa = function (ID) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_ORGAOS_EMPRESAS, "/").concat(ID);
                return [4 /*yield*/, fetchDeletar(URL)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
//AÇÕES
var ROTA_ACOES = 'acoes';
export var getAcoes = function (TextoBusca, PaginationModel) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_ACOES).concat(TextoBusca ? '/search/' + TextoBusca : '');
                return [4 /*yield*/, fetchConsultarPaginada(URL, PaginationModel)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var getAcoesAtivos = function () { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchConsultar("".concat(ROTA_ACOES, "/ativos"))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var getAcao = function (ID) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchConsultar("".concat(ROTA_ACOES, "/").concat(ID))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var cadastrarAcao = function (Dados) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_ACOES);
                return [4 /*yield*/, fetchCadastrar(URL, Dados)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var alterarAcao = function (Dados, ID) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_ACOES, "/").concat(ID);
                return [4 /*yield*/, fetchAlterar(URL, Dados)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var excluirAcao = function (ID) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_ACOES, "/").concat(ID);
                return [4 /*yield*/, fetchDeletar(URL)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
//FUNCIONALIDADES
var ROTA_FUNCIONALIDADES = 'funcionalidades';
export var getFuncionalidades = function (TextoBusca, PaginationModel) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_FUNCIONALIDADES).concat(TextoBusca ? '/search/' + TextoBusca : '');
                return [4 /*yield*/, fetchConsultarPaginada(URL, PaginationModel)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var getFuncionalidadesAtivos = function () { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchConsultar("".concat(ROTA_FUNCIONALIDADES, "/ativos"))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var getFuncionalidadesAcoesAtivos = function () { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchConsultar("".concat(ROTA_FUNCIONALIDADES, "/acoes"))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var getFuncionalidade = function (ID) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchConsultar("".concat(ROTA_FUNCIONALIDADES, "/").concat(ID))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var cadastrarFuncionalidade = function (Dados) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_FUNCIONALIDADES);
                return [4 /*yield*/, fetchCadastrar(URL, Dados)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var alterarFuncionalidade = function (Dados, ID) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_FUNCIONALIDADES, "/").concat(ID);
                return [4 /*yield*/, fetchAlterar(URL, Dados)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var excluirFuncionalidade = function (ID) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_FUNCIONALIDADES, "/").concat(ID);
                return [4 /*yield*/, fetchDeletar(URL)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
//TIPOS_SERVIDORES
var ROTA_TIPOS_SERVIDORES = 'tipos-servidores';
export var getTiposServidores = function (TextoBusca, PaginationModel) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_TIPOS_SERVIDORES).concat(TextoBusca ? '/search/' + TextoBusca : '');
                return [4 /*yield*/, fetchConsultarPaginada(URL, PaginationModel)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var getTipoServidor = function (ID) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchConsultar("".concat(ROTA_TIPOS_SERVIDORES, "/").concat(ID))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var cadastrarTipoServidor = function (Dados) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_TIPOS_SERVIDORES);
                return [4 /*yield*/, fetchCadastrar(URL, Dados)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var alterarTipoServidor = function (Dados, ID) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_TIPOS_SERVIDORES, "/").concat(ID);
                return [4 /*yield*/, fetchAlterar(URL, Dados)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var excluirTipoServidor = function (ID) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_TIPOS_SERVIDORES, "/").concat(ID);
                return [4 /*yield*/, fetchDeletar(URL)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
//USUÁRIOS
var ROTA_USUARIOS = 'usuarios';
export var getUsuarios = function (TextoBusca, Status, Perfil, PaginationModel) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_USUARIOS, "/").concat(TextoBusca, "/").concat(Status, "/").concat(Perfil);
                return [4 /*yield*/, fetchConsultarPaginada(URL, PaginationModel)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var getUsuariosPerfis = function () { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_USUARIOS, "/perfis");
                return [4 /*yield*/, fetchConsultar(URL)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var getCombosUsuarios = function () { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchConsultar("".concat(ROTA_USUARIOS, "/dados-combos"))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var getUsuario = function (ID) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchConsultar("".concat(ROTA_USUARIOS, "/").concat(ID))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var usuarioAuth = function (TOKEN) { return __awaiter(void 0, void 0, void 0, function () {
    var error_6;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, fetch("".concat(URL_API, "/").concat(ROTA_USUARIOS, "/auth"), {
                        headers: {
                            Authorization: "Bearer ".concat(TOKEN),
                        },
                    })];
            case 1: return [2 /*return*/, _a.sent()];
            case 2:
                error_6 = _a.sent();
                console.error(error_6);
                return [2 /*return*/, null];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getDadosUsuarioToken = function (TOKEN) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchConsultar("".concat(ROTA_USUARIOS, "/usuario-token"), TOKEN)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var cadastrarUsuario = function (Dados) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_USUARIOS);
                return [4 /*yield*/, fetchCadastrar(URL, Dados)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var alterarUsuario = function (Dados, ID) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_USUARIOS, "/").concat(ID);
                return [4 /*yield*/, fetchAlterar(URL, Dados)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var atualizarAcessoUsuario = function (TOKEN) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_USUARIOS, "/atualizar-acesso");
                return [4 /*yield*/, fetchAlterar(URL, null, TOKEN)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var getUsuarioPossuiNovasNotificacoes = function () { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchConsultar("".concat(ROTA_USUARIOS, "/possui-novas-notificacoes"))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var setUsuarioVisualizouNotificacoes = function () { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchAlterar("".concat(ROTA_USUARIOS, "/visualizou-notificacoes"))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var excluirUsuario = function (ID) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_USUARIOS, "/").concat(ID);
                return [4 /*yield*/, fetchDeletar(URL)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
//CATEGORIAS_CNH
var ROTA_CATEGORIAS_CNH = 'categorias-cnh';
export var getCategoriasCNH = function (TextoBusca, PaginationModel) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_CATEGORIAS_CNH).concat(TextoBusca ? '/search/' + TextoBusca : '');
                return [4 /*yield*/, fetchConsultarPaginada(URL, PaginationModel)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var getCategoriasCNHAtivos = function () { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchConsultar("".concat(ROTA_CATEGORIAS_CNH, "/ativos"))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var getCategoriaCNH = function (ID) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchConsultar("".concat(ROTA_CATEGORIAS_CNH, "/").concat(ID))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var cadastrarCategoriaCNH = function (Dados) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_CATEGORIAS_CNH);
                return [4 /*yield*/, fetchCadastrar(URL, Dados)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var alterarCategoriaCNH = function (Dados, ID) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_CATEGORIAS_CNH, "/").concat(ID);
                return [4 /*yield*/, fetchAlterar(URL, Dados)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var excluirCategoriaCNH = function (ID) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_CATEGORIAS_CNH, "/").concat(ID);
                return [4 /*yield*/, fetchDeletar(URL)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
var ROTA_CIDADES = 'cidades';
export var getCidadesAtivos = function (Estado) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchConsultar("".concat(ROTA_CIDADES, "/ativos").concat(Estado ? "/".concat(Estado) : ''))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
//ESTADOS
var ROTA_ESTADOS = 'estados';
export var getEstados = function (TextoBusca, PaginationModel) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_ESTADOS).concat(TextoBusca ? '/search/' + TextoBusca : '');
                return [4 /*yield*/, fetchConsultarPaginada(URL, PaginationModel)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var getEstadosAtivos = function () { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchConsultar("".concat(ROTA_ESTADOS, "/ativos"))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var getEstado = function (ID) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchConsultar("".concat(ROTA_ESTADOS, "/").concat(ID))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var cadastrarEstado = function (Dados) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_ESTADOS);
                return [4 /*yield*/, fetchCadastrar(URL, Dados)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var alterarEstado = function (Dados, ID) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_ESTADOS, "/").concat(ID);
                return [4 /*yield*/, fetchAlterar(URL, Dados)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var excluirEstado = function (ID) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_ESTADOS, "/").concat(ID);
                return [4 /*yield*/, fetchDeletar(URL)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
//GRAUS_ESCOLARIDADE
var ROTA_GRAUS_ESCOLARIDADE = 'graus-escolaridade';
export var getGrausEscolaridade = function (TextoBusca, PaginationModel) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_GRAUS_ESCOLARIDADE).concat(TextoBusca ? '/search/' + TextoBusca : '');
                return [4 /*yield*/, fetchConsultarPaginada(URL, PaginationModel)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var getGrausEscolaridadeAtivos = function () { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchConsultar("".concat(ROTA_GRAUS_ESCOLARIDADE, "/ativos"))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var getGrauEscolaridade = function (ID) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchConsultar("".concat(ROTA_GRAUS_ESCOLARIDADE, "/").concat(ID))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var cadastrarGrauEscolaridade = function (Dados) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_GRAUS_ESCOLARIDADE);
                return [4 /*yield*/, fetchCadastrar(URL, Dados)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var alterarGrauEscolaridade = function (Dados, ID) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_GRAUS_ESCOLARIDADE, "/").concat(ID);
                return [4 /*yield*/, fetchAlterar(URL, Dados)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var excluirGrauEscolaridade = function (ID) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_GRAUS_ESCOLARIDADE, "/").concat(ID);
                return [4 /*yield*/, fetchDeletar("".concat(URL), REST_METHODS.DELETE)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
//NIVEIS_ESCOLARIDADE
var ROTA_NIVEIS_ESCOLARIDADE = 'niveis-escolaridade';
export var getNiveisEscolaridade = function (TextoBusca, PaginationModel) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_NIVEIS_ESCOLARIDADE).concat(TextoBusca ? '/search/' + TextoBusca : '');
                return [4 /*yield*/, fetchConsultarPaginada(URL, PaginationModel)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var getNiveisEscolaridadeAtivos = function () { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchConsultar("".concat(ROTA_NIVEIS_ESCOLARIDADE, "/ativos"))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var getNivelEscolaridade = function (ID) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchConsultar("".concat(ROTA_NIVEIS_ESCOLARIDADE, "/").concat(ID))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var cadastrarNivelEscolaridade = function (Dados) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_NIVEIS_ESCOLARIDADE);
                return [4 /*yield*/, fetchCadastrar(URL, Dados)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var alterarNivelEscolaridade = function (Dados, ID) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_NIVEIS_ESCOLARIDADE, "/").concat(ID);
                return [4 /*yield*/, fetchAlterar(URL, Dados)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var excluirNivelEscolaridade = function (ID) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_NIVEIS_ESCOLARIDADE, "/").concat(ID);
                return [4 /*yield*/, fetchDeletar(URL)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
//ORGAOS_EMISSOES_RG
var ROTA_ORGAOS_EMISSOES_RG = 'orgaos-emissores-rg';
export var getOrgaosEmissoresRG = function (TextoBusca, PaginationModel) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_ORGAOS_EMISSOES_RG).concat(TextoBusca ? '/search/' + TextoBusca : '');
                return [4 /*yield*/, fetchConsultarPaginada(URL, PaginationModel)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var getOrgaosEmissoresRGAtivos = function () { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchConsultar("".concat(ROTA_ORGAOS_EMISSOES_RG, "/ativos"))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var getOrgaoEmissorRG = function (ID) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchConsultar("".concat(ROTA_ORGAOS_EMISSOES_RG, "/").concat(ID))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var cadastrarOrgaoEmissorRG = function (Dados) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_ORGAOS_EMISSOES_RG);
                return [4 /*yield*/, fetchCadastrar(URL, Dados)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var alterarOrgaoEmissorRG = function (Dados, ID) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_ORGAOS_EMISSOES_RG, "/").concat(ID);
                return [4 /*yield*/, fetchAlterar(URL, Dados)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var excluirOrgaoEmissorRG = function (ID) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_ORGAOS_EMISSOES_RG, "/").concat(ID);
                return [4 /*yield*/, fetchDeletar(URL)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
//ESTADOS_CIVIS
var ROTA_ESTADOS_CIVIS = 'estados-civis';
export var getEstadosCivis = function (TextoBusca, PaginationModel) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_ESTADOS_CIVIS).concat(TextoBusca ? '/search/' + TextoBusca : '');
                return [4 /*yield*/, fetchConsultarPaginada(URL, PaginationModel)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var getEstadosCivisAtivos = function () { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchConsultar("".concat(ROTA_ESTADOS_CIVIS, "/ativos"))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var getEstadoCivil = function (ID) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchConsultar("".concat(ROTA_ESTADOS_CIVIS, "/").concat(ID))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var cadastrarEstadoCivil = function (Dados) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_ESTADOS_CIVIS);
                return [4 /*yield*/, fetchCadastrar(URL, Dados)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var alterarEstadoCivil = function (Dados, ID) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_ESTADOS_CIVIS, "/").concat(ID);
                return [4 /*yield*/, fetchAlterar(URL, Dados)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var excluirEstadoCivil = function (ID) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_ESTADOS_CIVIS, "/").concat(ID);
                return [4 /*yield*/, fetchDeletar(URL)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
//TIPOS_LOGRADOUROS
var ROTA_TIPOS_LOGRADOUROS = 'tipos-logradouros';
export var getTiposLogradouros = function (TextoBusca, PaginationModel) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_TIPOS_LOGRADOUROS).concat(TextoBusca ? '/search/' + TextoBusca : '');
                return [4 /*yield*/, fetchConsultarPaginada(URL, PaginationModel)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var getTiposLogradourosAtivos = function () { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchConsultar("".concat(ROTA_TIPOS_LOGRADOUROS, "/ativos"))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var getTipoLogradouro = function (ID) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchConsultar("".concat(ROTA_TIPOS_LOGRADOUROS, "/").concat(ID))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var cadastrarTipoLogradouro = function (Dados) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_TIPOS_LOGRADOUROS);
                return [4 /*yield*/, fetchCadastrar(URL, Dados)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var alterarTipoLogradouro = function (Dados, ID) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_TIPOS_LOGRADOUROS, "/").concat(ID);
                return [4 /*yield*/, fetchAlterar(URL, Dados)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var excluirTipoLogradouro = function (ID) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_TIPOS_LOGRADOUROS, "/").concat(ID);
                return [4 /*yield*/, fetchDeletar(URL)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
//ORIENTACOES_SEXUAIS
var ROTA_ORIENTACOES_SEXUAIS = 'orientacoes-sexuais';
export var getOrientacoesSexuais = function (TextoBusca, PaginationModel) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_ORIENTACOES_SEXUAIS).concat(TextoBusca ? '/search/' + TextoBusca : '');
                return [4 /*yield*/, fetchConsultarPaginada(URL, PaginationModel)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var getOrientacoesSexuaisAtivos = function () { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchConsultar("".concat(ROTA_ORIENTACOES_SEXUAIS, "/ativos"))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var getOrientacaoSexual = function (ID) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchConsultar("".concat(ROTA_ORIENTACOES_SEXUAIS, "/").concat(ID))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var cadastrarOrientacaoSexual = function (Dados) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_ORIENTACOES_SEXUAIS);
                return [4 /*yield*/, fetchCadastrar(URL, Dados)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var alterarOrientacaoSexual = function (Dados, ID) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_ORIENTACOES_SEXUAIS, "/").concat(ID);
                return [4 /*yield*/, fetchAlterar(URL, Dados)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var excluirOrientacaoSexual = function (ID) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_ORIENTACOES_SEXUAIS, "/").concat(ID);
                return [4 /*yield*/, fetchDeletar(URL)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
//IDENTIDADES_GENERO
var ROTA_IDENTIDADES_GENERO = 'identidades-genero';
export var getIdentidadesGenero = function (TextoBusca, PaginationModel) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_IDENTIDADES_GENERO).concat(TextoBusca ? '/search/' + TextoBusca : '');
                return [4 /*yield*/, fetchConsultarPaginada(URL, PaginationModel)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var getIdentidadesGeneroAtivos = function () { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchConsultar("".concat(ROTA_IDENTIDADES_GENERO, "/ativos"))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var getIdentidadeGenero = function (ID) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchConsultar("".concat(ROTA_IDENTIDADES_GENERO, "/").concat(ID))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var cadastrarIdentidadeGenero = function (Dados) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_IDENTIDADES_GENERO);
                return [4 /*yield*/, fetchCadastrar(URL, Dados)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var alterarIdentidadeGenero = function (Dados, ID) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_IDENTIDADES_GENERO, "/").concat(ID);
                return [4 /*yield*/, fetchAlterar(URL, Dados)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var excluirIdentidadeGenero = function (ID) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_IDENTIDADES_GENERO, "/").concat(ID);
                return [4 /*yield*/, fetchDeletar("".concat(URL), REST_METHODS.DELETE)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
//TIPOS_RACAS_CORES
var ROTA_TIPOS_RACAS_CORES = 'tipos-racas-cores';
export var getTiposRacasCores = function (TextoBusca, PaginationModel) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_TIPOS_RACAS_CORES).concat(TextoBusca ? '/search/' + TextoBusca : '');
                return [4 /*yield*/, fetchConsultarPaginada(URL, PaginationModel)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var getTiposRacasCoresAtivos = function () { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchConsultar("".concat(ROTA_TIPOS_RACAS_CORES, "/ativos"))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var getTipoRacaCor = function (ID) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchConsultar("".concat(ROTA_TIPOS_RACAS_CORES, "/").concat(ID))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var cadastrarTipoRacaCor = function (Dados) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_TIPOS_RACAS_CORES);
                return [4 /*yield*/, fetchCadastrar(URL, Dados)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var alterarTipoRacaCor = function (Dados, ID) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_TIPOS_RACAS_CORES, "/").concat(ID);
                return [4 /*yield*/, fetchAlterar(URL, Dados)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var excluirTipoRacaCor = function (ID) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_TIPOS_RACAS_CORES, "/").concat(ID);
                return [4 /*yield*/, fetchDeletar(URL)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
//CONTRATOS E CONVENIOS / INSTITUICOES
var ROTA_INSTITUICOES = 'contratos-convenios/instituicoes';
export var getInstituicoes = function (TextoBusca, PaginationModel) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_INSTITUICOES).concat(TextoBusca ? '/search/' + TextoBusca : '');
                return [4 /*yield*/, fetchConsultarPaginada(URL, PaginationModel)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var getInstituicoesAtivos = function () { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchConsultar("".concat(ROTA_INSTITUICOES, "/ativos"))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
//INSTITUICOES / DADOS
export var getInstituicaoDados = function (IDInstituicao) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchConsultar("".concat(ROTA_INSTITUICOES, "/").concat(IDInstituicao))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var getCombosInstituicoesDados = function () { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchConsultar("".concat(ROTA_INSTITUICOES, "/combos-dados"))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var cadastrarInstituicao = function (Dados) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_INSTITUICOES);
                return [4 /*yield*/, fetchCadastrar(URL, Dados)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var alterarInstituicao = function (Dados, ID) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_INSTITUICOES, "/").concat(ID);
                return [4 /*yield*/, fetchAlterar(URL, Dados)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var excluirInstituicao = function (ID) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_INSTITUICOES, "/").concat(ID);
                return [4 /*yield*/, fetchDeletar(URL, REST_METHODS.DELETE)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
//INSTITUICOES / DOCUMENTOS
export var getInstituicaoDocumentos = function (IDInstituicao) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchConsultar("".concat(ROTA_INSTITUICOES, "/documentos/").concat(IDInstituicao))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var getInstituicoesTiposTermosDocumentosAtivos = function () { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchConsultar("".concat(ROTA_INSTITUICOES, "/tipos-termos-documentos"))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var getInstituicoesTiposContratosAtivos = function () { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchConsultar("".concat(ROTA_INSTITUICOES, "/tipos-contratos"))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var cadastrarInstituicaoDocumento = function (Dados, IDInstituicao) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_INSTITUICOES, "/documentos/").concat(IDInstituicao);
                return [4 /*yield*/, fetchArquivos(URL, Dados)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var alterarInstituicaoDocumento = function (Dados, IDDocumento) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_INSTITUICOES, "/documentos/").concat(IDDocumento);
                return [4 /*yield*/, fetchAlterar(URL, Dados)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var excluirInstituicaoDocumento = function (IDDocumento) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_INSTITUICOES, "/documentos/").concat(IDDocumento);
                return [4 /*yield*/, fetchDeletar(URL)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
//INSTITUICOES / TERMOS E CONTRATOS
export var getInstituicaoTermosContratos = function (IDInstituicao) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchConsultar("".concat(ROTA_INSTITUICOES, "/termos-contratos/").concat(IDInstituicao))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var cadastrarInstituicaoTermoContrato = function (Dados, IDInstituicao) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_INSTITUICOES, "/termos-contratos/").concat(IDInstituicao);
                return [4 /*yield*/, fetchCadastrar(URL, Dados)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var alterarInstituicaoTermoContrato = function (Dados, IDTermoContrato) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_INSTITUICOES, "/termos-contratos/").concat(IDTermoContrato);
                return [4 /*yield*/, fetchAlterar(URL, Dados)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var excluirInstituicaoTermoContrato = function (IDTermoContrato) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_INSTITUICOES, "/termos-contratos/").concat(IDTermoContrato);
                return [4 /*yield*/, fetchDeletar(URL)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
//CONTRATOS E CONVENIOS / TIPOS DE CONTRATOS
var ROTA_TIPOS_CONTRATOS = 'contratos-convenios/tipos-contratos';
export var getTiposContratos = function (TextoBusca, PaginationModel) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_TIPOS_CONTRATOS).concat(TextoBusca ? '/search/' + TextoBusca : '');
                return [4 /*yield*/, fetchConsultarPaginada(URL, PaginationModel)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var getTiposContratosAtivos = function () { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchConsultar("".concat(ROTA_TIPOS_CONTRATOS, "/ativos"))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var getTipoContrato = function (ID) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchConsultar("".concat(ROTA_TIPOS_CONTRATOS, "/").concat(ID))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var cadastrarTipoContrato = function (Dados) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_TIPOS_CONTRATOS);
                return [4 /*yield*/, fetchCadastrar(URL, Dados)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var alterarTipoContrato = function (Dados, ID) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_TIPOS_CONTRATOS, "/").concat(ID);
                return [4 /*yield*/, fetchAlterar(URL, Dados)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var excluirTipoContrato = function (ID) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_TIPOS_CONTRATOS, "/").concat(ID);
                return [4 /*yield*/, fetchDeletar(URL)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
//CONTRATOS E CONVENIOS / TIPOS DE DOCUMENTOS
var ROTA_TIPOS_TERMOS_DOCUMENTOS = 'contratos-convenios/tipos-termos-documentos';
export var getTiposTermosDocumentos = function (TextoBusca, PaginationModel) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_TIPOS_TERMOS_DOCUMENTOS).concat(TextoBusca ? '/search/' + TextoBusca : '');
                return [4 /*yield*/, fetchConsultarPaginada(URL, PaginationModel)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var getTiposTermosDocumentosAtivos = function () { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchConsultar("".concat(ROTA_TIPOS_TERMOS_DOCUMENTOS, "/ativos"))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var getTipoTermoDocumento = function (ID) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchConsultar("".concat(ROTA_TIPOS_TERMOS_DOCUMENTOS, "/").concat(ID))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var cadastrarTipoTermoDocumento = function (Dados) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_TIPOS_TERMOS_DOCUMENTOS);
                return [4 /*yield*/, fetchCadastrar(URL, Dados)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var alterarTipoTermoDocumento = function (Dados, ID) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_TIPOS_TERMOS_DOCUMENTOS, "/").concat(ID);
                return [4 /*yield*/, fetchAlterar(URL, Dados)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var excluirTipoTermoDocumento = function (ID) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_TIPOS_TERMOS_DOCUMENTOS, "/").concat(ID);
                return [4 /*yield*/, fetchDeletar(URL)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
//CONTRATOS E CONVENIOS / TIPOS DE EMPRESAS
var ROTA_TIPOS_EMPRESAS = 'contratos-convenios/tipos-empresas';
export var getTiposEmpresas = function (TextoBusca, PaginationModel) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_TIPOS_EMPRESAS).concat(TextoBusca ? '/search/' + TextoBusca : '');
                return [4 /*yield*/, fetchConsultarPaginada(URL, PaginationModel)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var getTiposEmpresasAtivos = function () { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchConsultar("".concat(ROTA_TIPOS_EMPRESAS, "/ativos"))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var getTipoEmpresa = function (ID) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchConsultar("".concat(ROTA_TIPOS_EMPRESAS, "/").concat(ID))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var cadastrarTipoEmpresa = function (Dados) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_TIPOS_EMPRESAS);
                return [4 /*yield*/, fetchCadastrar(URL, Dados)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var alterarTipoEmpresa = function (Dados, ID) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_TIPOS_EMPRESAS, "/").concat(ID);
                return [4 /*yield*/, fetchAlterar(URL, Dados)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var excluirTipoEmpresa = function (ID) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_TIPOS_EMPRESAS, "/").concat(ID);
                return [4 /*yield*/, fetchDeletar(URL)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
//CONTRATOS E CONVENIOS / AGÊNCIAS BANCÁRIAS
var ROTA_AGENCIAS_BANCARIAS = 'bancos';
export var getAgenciasBancarias = function (TextoBusca, PaginationModel) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_AGENCIAS_BANCARIAS).concat(TextoBusca ? '/search/' + TextoBusca : '');
                return [4 /*yield*/, fetchConsultarPaginada(URL, PaginationModel)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var getAgenciasBancariasAtivos = function () { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchConsultar("".concat(ROTA_AGENCIAS_BANCARIAS, "/ativos"))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var getAgenciaBancaria = function (ID) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchConsultar("".concat(ROTA_AGENCIAS_BANCARIAS, "/").concat(ID))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var cadastrarAgenciaBancaria = function (Dados) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_AGENCIAS_BANCARIAS);
                return [4 /*yield*/, fetchCadastrar(URL, Dados)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var alterarAgenciaBancaria = function (Dados, ID) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_AGENCIAS_BANCARIAS, "/").concat(ID);
                return [4 /*yield*/, fetchAlterar(URL, Dados)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var excluirAgenciaBancaria = function (ID) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_AGENCIAS_BANCARIAS, "/").concat(ID);
                return [4 /*yield*/, fetchDeletar(URL)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var consultarEnderecoCEP = function (CEP) { return __awaiter(void 0, void 0, void 0, function () {
    var URL, response, RespJson, error_7;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 4]);
                URL = "https://viacep.com.br/ws/".concat(CEP, "/json/");
                return [4 /*yield*/, fetch(URL, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    })];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, (response === null || response === void 0 ? void 0 : response.json())];
            case 2:
                RespJson = _a.sent();
                return [2 /*return*/, RespJson];
            case 3:
                error_7 = _a.sent();
                console.error('Erro ao executar a solicitação para a API : ', error_7);
                return [2 /*return*/, getRespostasPadraoAPI(null)];
            case 4: return [2 /*return*/];
        }
    });
}); };
//CONTRATOS E CONVENIOS / TIPOS CONTAS BANCARIAS
var ROTA_TIPOS_CONTAS_BANCARIAS = 'contratos-convenios/tipos-contas-bancarias';
export var getTiposContasBancarias = function (TextoBusca, PaginationModel) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_TIPOS_CONTAS_BANCARIAS).concat(TextoBusca ? '/search/' + TextoBusca : '');
                return [4 /*yield*/, fetchConsultarPaginada(URL, PaginationModel)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var getTiposContasBancariasAtivos = function () { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchConsultar("".concat(ROTA_TIPOS_CONTAS_BANCARIAS, "/ativos"))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var getTipoContaBancaria = function (ID) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchConsultar("".concat(ROTA_TIPOS_CONTAS_BANCARIAS, "/").concat(ID))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var cadastrarTipoContaBancaria = function (Dados) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_TIPOS_CONTAS_BANCARIAS);
                return [4 /*yield*/, fetchCadastrar(URL, Dados)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var alterarTipoContaBancaria = function (Dados, ID) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_TIPOS_CONTAS_BANCARIAS, "/").concat(ID);
                return [4 /*yield*/, fetchAlterar(URL, Dados)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var excluirTipoContaBancaria = function (ID) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_TIPOS_CONTAS_BANCARIAS, "/").concat(ID);
                return [4 /*yield*/, fetchDeletar(URL)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
//NOTIFICACOES
var ROTA_NOTIFICACOES = 'notificacoes';
export var getNotificacoesUsuarioLogado = function (TextoBusca, Status, PaginationModel) { return __awaiter(void 0, void 0, void 0, function () {
    var ConsultarEncode, URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                ConsultarEncode = TextoBusca ? encodeURIComponent(TextoBusca) : null;
                URL = "".concat(ROTA_NOTIFICACOES, "/usuario/").concat(Status).concat(ConsultarEncode ? "/".concat(ConsultarEncode) : '');
                return [4 /*yield*/, fetchConsultarPaginada(URL, PaginationModel)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var alterarNotificacao = function (Dados, ID) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_NOTIFICACOES, "/").concat(ID);
                return [4 /*yield*/, fetchAlterar(URL, Dados)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var alterarArrayNotificacoes = function (ArrayNotificacoes) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_NOTIFICACOES);
                return [4 /*yield*/, fetchAlterar(URL, ArrayNotificacoes)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
