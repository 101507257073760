import React, { createContext, useState, useEffect, ReactNode } from 'react';
const AuthContext = createContext(null);
import { getDadosUsuarioLogadoLocalStorage, getURLMTLogin, getURLMTLogout } from '../../services/mt_login';
import { useNavigate } from 'react-router-dom';
import { PAGINAS, ROUTES } from '../../routes';
import { criptografar } from '../../utils';
import { getTokenFromCookie } from '../../services/mt_login';

export const AuthProvider = ({ children }) => {
  const [User, setUser] = useState(getDadosUsuarioLogadoLocalStorage());
  const [Mensagens, setMensagens] = useState([]);
  const [DEV, setDEV] = useState(process.env.AMBIENTE == 'DEV');
  const navigate = useNavigate();

  useEffect(() => {}, []);

  const auth = (Code, Pagina, Modo) => {
    if (User || Code) {
      if (Pagina == PAGINAS.HOME_PAINEL) return;

      const TokenAcesso = getTokenFromCookie();
      if (!Code && !TokenAcesso) {
        alert('Sessão expirada. Faça login novamente.');
        navigate(ROUTES.LOGOUT);
      } else {
        if (DEV && (Pagina == 'Ações' || Pagina == 'Funcionalidades')) return;

        if (!verificarPermissoesPagina(Pagina, Modo)) {
          navigate(ROUTES.SEM_PERMISSAO);
        }
      }
    } else {
      navigate(ROUTES.LOGOUT);
    }

    //console.log('process.env.AMBIENTE : ' + process.env.AMBIENTE);
  };

  const signInContext = (UserParm) => {
    const { access_token, nome, cpf, email, matricula, funcionalidades_ajustadas } = UserParm;

    const Usuario = {
      nome,
      cpf,
      email,
      matricula,
      permissoes: funcionalidades_ajustadas,
    };

    DEV && console.log('access_token : ' + access_token);
    DEV && console.log('usuario_logado : ' + JSON.stringify(Usuario));

    localStorage.setItem('usuario_logado', criptografar(JSON.stringify(Usuario)));
    setTokenCookie(access_token);
    setUser(Usuario);
  };

  const setTokenCookie = (token) => {
    const days = 10;
    const expires = new Date(Date.now() + days * 24 * 60 * 60 * 1000).toUTCString();

    if (process.env.LOCALHOST) {
      console.log('setTokenCookie - process.env.LOCALHOST : ' + process.env.LOCALHOST);
      document.cookie = `token=${token}; expires=${expires}; path=/; SameSite=Lax`;
    } else {
      document.cookie = `token=${token}; expires=${expires}; path=/; Secure; SameSite=None`;
    }
  };

  const getAcoesFuncionalidade = (FuncionalidadeParm) => {
    const Permissoes = User?.permissoes;
    const Acoes = Permissoes?.find((Funcionalidade) => Funcionalidade.nome == FuncionalidadeParm)?.acoes;

    let AcoesArray = {};

    for (let index = 0; Acoes && index < Acoes.length; index++) {
      const Acao = Acoes[index];

      AcoesArray[Acao.nome?.toUpperCase()] = true;
      AcoesArray[Acao.nome?.toLowerCase()] = true;
    }

    DEV && console.log('getAcoesFuncionalidade - AcoesArray : ' + JSON.stringify(AcoesArray));
    return AcoesArray;
  };

  const verificarPermissoesPagina = (Pagina, Modo) => {
    if (DEV) {
      console.log('verificarPermissoesPagina');
      console.log('Pagina : ' + Pagina);
      console.log('Modo : ' + Modo);
    }

    const Acoes = getAcoesFuncionalidade(Pagina);
    const ModoUpper = Modo?.toUpperCase();

    const PossuiPermissao = Acoes[ModoUpper];
    return PossuiPermissao;
  };

  const signOutContext = async () => {
    localStorage.removeItem('usuario_logado');
    document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

    setUser(null);
  };

  const getURLBase = () => {
    let Dominio = window.location.hostname;

    let URLBase = Dominio.indexOf('localhost') >= 0 ? 'http://localhost:3006' : `https://${Dominio}`;
    //console.log('Dominio : ' + Dominio);
    //console.log('URLBase : ' + URLBase);

    return URLBase;
  };

  const getNomeUsuario = () => {
    return User ? `${User.nome}` : '';
  };

  const getMensagens = () => {
    const MensagensAux = Mensagens;
    setMensagens([]);

    return MensagensAux;
  };

  const addMensagem = (MSG) => {
    let MensagensAux = Mensagens;
    MensagensAux.push(MSG);
    setMensagens(MensagensAux);

    return MensagensAux;
  };

  return (
    <AuthContext.Provider
      value={{
        User: User,
        NomeUsuario: getNomeUsuario(),
        setUser: setUser,
        DEV: DEV,
        signInContext: signInContext,
        signOutContext: signOutContext,
        getURLBase: getURLBase,
        auth: auth,
        addMensagem: addMensagem,
        getMensagens: getMensagens,
        getAcoesFuncionalidade: getAcoesFuncionalidade,
        verificarPermissoesPagina: verificarPermissoesPagina,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
